import { template as template_00761ba4c8aa4a4685ee327f72257b86 } from "@ember/template-compiler";
const FKLabel = template_00761ba4c8aa4a4685ee327f72257b86(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

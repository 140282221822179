import { template as template_b20dce3b1f3a4d2787a4d1d7f4d9093f } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_b20dce3b1f3a4d2787a4d1d7f4d9093f(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;

import { template as template_3df6f6a41af74b7faad2493c58e10699 } from "@ember/template-compiler";
const SidebarSectionMessage = template_3df6f6a41af74b7faad2493c58e10699(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
